import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout pageTitle="Herzlich Willkommen">
      Hier entsteht der Webauftritt von YAM business.solutions.<br/>
		    Wir möchten nicht weniger als die Administration von Unternehmen revolutionieren und 
    		die Eigentümer und Geschäftsführer besser unterstützen. Haben wir Ihre Neugierde geweckt?<br/>
    		<b>Sprechen Sie uns <a href="mailto:info@yam-bs.de">an!</a></b>
        <StaticImage
//        alt="Der heilige Ort für Fans des #Effzeh Kölle"
//        src="../images/rheinenergiestadion.jpg"
        />
    </Layout>
  )
}

export default IndexPage